import mParticle, {
  IdentityResult,
  MPConfiguration,
  errorObject,
} from '@mparticle/web-sdk';
import { getCoreConfig } from '@/common/config/config';
import {
  isClientSide,
  isProdEnv,
  isServerSide,
} from '@/common/utils/environment/environment';
import { HTTP_STATUS_CODE } from '@/helpers/httpStatusCode';
import {
  getAnonymousId,
  setAnonymousId,
  trackingMessageError,
} from '../../tracking';
import { customLoginEvent, customLogoutEvent } from './customEvents';
import { getLoggedUserIdentities, identityCallback } from './helpers';
import {
  CustomBatch,
  CustomMPConfiguration,
  DataType,
  EventBatch,
} from './trackingConfigs.types';

const { publicRuntimeConfig } = getCoreConfig();

export const loginCallback =
  (isMobile = false) =>
  (result: IdentityResult) => {
    if (result?.httpCode === HTTP_STATUS_CODE.OK) {
      customLoginEvent(isMobile);
    }
  };

export const loginOnMparticle = (data: DataType, isMobile = false) => {
  const isLogged = mParticle.Identity?.getCurrentUser()?.isLoggedIn();
  if (!isLogged) {
    mParticleLoginRequest(data, isMobile);
  }
};

export const mParticleLoginRequest = (data: DataType, isMobile = false) => {
  if (data.customerid && data.email) {
    try {
      mParticle?.Identity?.login(
        {
          userIdentities: {
            email: data.email,
            customerid: `BU-FISIA_ID-${data.customerid}`,
          },
        },
        loginCallback(isMobile),
      );
    } catch (err: unknown) {
      const resultError = err as errorObject;
      trackingMessageError({
        message: 'Login Call mParticle error',
        flow: 'mParticle_login_flow',
        extraDetails: {
          errorMessage: resultError?.message,
        },
        checkpoint: 'error_try_mParticleLogin',
        namespace: 'mParticle()',
      });
    }
  }
};

export const getMparticleConfig = (data?: DataType, isMobile?: boolean) => {
  setAnonymousId();
  const mParticleConfig = {
    isDevelopmentMode: !isProdEnv,
    identifyRequest: {
      userIdentities: getLoggedUserIdentities(data),
    },
    identityCallback: () => identityCallback(data, isMobile),
    dataPlan: {
      planId: 'data_plan',
      planVersion: 1,
    },
    deviceId: getAnonymousId(),
    useCookieStorage: true,
    maxCookieSize: 1500,
    v1SecureServiceUrl: 'mparticle.nike.com.br/webevents/v1/JS/',
    v2SecureServiceUrl: 'mparticle.nike.com.br/webevents/v2/JS/',
    v3SecureServiceUrl: 'mparticle.nike.com.br/webevents/v3/JS/',
    configUrl: 'mparticle.nike.com.br/tags/JS/v2/',
    identityUrl: 'mparticle.nike.com.br/identity/v1/',
    aliasUrl: 'mparticle.nike.com.br/webevents/v1/identity/',
    onCreateBatch: (batch: CustomBatch) => {
      // the following removes Application State Transition (AST) events, except for those uploaded on first-run
      // First-run AST events are used by many server-side integrations to mark an "install" and are used by
      // mParticle to ensure there is a user profile created
      batch.events = batch.events?.filter((event: EventBatch) => {
        return (
          event.event_type !== 'application_state_transition' ||
          event.data?.is_first_run
        );
      });
      if (batch.events?.length === 0) {
        return null;
      }

      return batch; // Remember to return the batch!
    },
  };
  return mParticleConfig;
};

export const startMParticle = (
  mParticleConfig: MPConfiguration | CustomMPConfiguration,
) => {
  if (isServerSide()) {
    return;
  }
  //configure the SDK
  mParticle.init(
    publicRuntimeConfig.login.mParticle.mParticleSecret,
    mParticleConfig as MPConfiguration,
  );
};

export const mParticleLogout = (isMobile: boolean) => {
  const isLogged = mParticle.Identity?.getCurrentUser()?.isLoggedIn();
  const hasMparticleCustomer =
    mParticle.Identity?.getCurrentUser()?.getUserIdentities()?.userIdentities
      ?.customerid;

  if (isLogged && isClientSide() && hasMparticleCustomer) {
    customLogoutEvent(isMobile);
    try {
      mParticle.Identity.logout({});
    } catch (err: unknown) {
      const resultError = err as errorObject;
      trackingMessageError({
        message: 'Logout Call mParticle error',
        flow: 'mParticle_logout_flow',
        extraDetails: {
          errorMessage: resultError?.message,
        },
        checkpoint: 'error_try_mParticleLogout',
        namespace: 'mParticle()',
      });
    }
  }
};
