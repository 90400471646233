import mParticle from '@mparticle/web-sdk';
import { getDateOnCDPFormat } from '@/common/utils/funcs/getDateOnCDPFormat/getDateOnCDPFormat';
import { getStorageItem } from '@/helpers/localStorage';
import { calculateSessionDuration, getUrlParams } from './helpers';
import {
  FreightInfo,
  ItemOnAddToCart,
  ItemsOnBeginCheckout,
  LoginAttributes,
  LogoutAttributes,
} from './trackingConfigs.types';

export const customLoginEvent = (isMobile = false) => {
  const date = new Date();
  const dateTime = date.getTime();

  localStorage.setItem('session_begin', JSON.stringify(dateTime));

  const attributes = <LoginAttributes>{
    channelId: isMobile ? 7 : 1,
    url: window?.location.href,
    loginType: 'email',
    performedAt: getDateOnCDPFormat(),
    isSuccess: true,
  };
  const finalAttributes = getUrlParams(attributes);

  mParticle?.logEvent('login', mParticle.EventType.Other, finalAttributes);
};

export const customLogoutEvent = (isMobile: boolean) => {
  const sessionStartTime = <string>getStorageItem('session_begin');

  const sessionDurationFormatted = calculateSessionDuration(sessionStartTime);

  const attributes = <LogoutAttributes>{
    channelId: isMobile ? 7 : 1,
    url: window?.location.href,
    totalSessionDuration: parseInt(sessionDurationFormatted),
    isSuccess: true,
    performedAt: getDateOnCDPFormat(),
  };

  const finalAttributes = getUrlParams(attributes);

  mParticle?.logEvent('logout', mParticle.EventType.Other, finalAttributes);
};

export const formatNumber = (number: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(number)
    .replaceAll(',', '');
};

const getCustomProducts = (products: ItemsOnBeginCheckout[]) => {
  let hasGiftOrder = false;
  const customProducts = products.map((item) => {
    const customProductAttributes = {
      basePrice: formatNumber(item.basePrice || item.price || 0),
      size: item.size,
      isGift: false,
    };

    const product = window.mParticle.eCommerce.createProduct(
      item.name,
      item.id,
      item.price,
      item.quantity,
      item.color,
      item.category || undefined,
      undefined,
      undefined,
      undefined,
      customProductAttributes,
    );

    if (item.isPresent) {
      hasGiftOrder = true;
    }

    return product;
  });

  return { customProducts, hasGiftOrder };
};

export const mParticleBeginCheckoutEvent = (
  isMobile = false,
  items: ItemsOnBeginCheckout[],
  freightInfo: FreightInfo,
  coupon?: string,
  errorMessage = '',
  isGiftCard?: boolean,
  isExchange?: boolean,
) => {
  const { customProducts, hasGiftOrder } = getCustomProducts(items);

  const itemPrices = items.map((item) => item.totalPrice);
  const productsTotalSum = itemPrices.reduce((acc, price) => acc + price, 0);
  const freightCost = freightInfo?.shippingCost ? freightInfo?.shippingCost : 0;
  const grandTotal = productsTotalSum
    ? formatNumber(productsTotalSum + freightCost)
    : '0.00';

  const hasShippingCost =
    (freightInfo?.shippingCost as number) >= 0 &&
    freightInfo?.shippingCost !== null;

  const freightPrice = hasShippingCost
    ? formatNumber(freightInfo.shippingCost || 0)
    : undefined;

  const customAttributes = {
    channelId: isMobile ? 7 : 1,
    url: window?.location.href,
    isGift: hasGiftOrder,
    errorDescription: errorMessage,
    grandTotal: grandTotal,
    couponName: coupon || '',
    freightPrice: freightPrice,
    freightPostCode: freightInfo?.zipCode || '',
    isGiftCard,
    isExchange,
    performedAt: getDateOnCDPFormat(),
  };

  mParticle?.eCommerce?.logProductAction(
    mParticle.ProductActionType.Checkout,
    customProducts,
    customAttributes,
    {},
  );
};

export const mParticleAddToCartEvent = (
  isMobile = false,
  item: ItemOnAddToCart,
  origin: string,
) => {
  const customAttributes = {
    channelId: isMobile ? 7 : 1,
    productSku: item.sku,
    productName: item.name,
    productPrice: formatNumber(item.price || 0),
    productBrand: item.brand,
    productBasePrice: formatNumber(item.basePrice || 0),
    productQuantity: item.quantity,
    productCategory: item.category,
    productColor: item.color,
    productSize: item.size,
    url: window?.location.href,
    urlOrigin: origin,
    performedAt: getDateOnCDPFormat(),
  };

  const finalAttributes = getUrlParams(customAttributes);

  mParticle?.eCommerce?.logProductAction(
    mParticle.ProductActionType.AddToCart,
    [],
    finalAttributes,
    {},
  );
};
