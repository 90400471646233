import mParticle from '@mparticle/web-sdk';
import { getGaUserId } from '../gtm/gtm';
import { loginOnMparticle, mParticleLogout } from './trackingConfigs';
import {
  DataType,
  LoginAttributes,
  LogoutAttributes,
  customAttributesType,
} from './trackingConfigs.types';

export const identityCallback = (data?: DataType, isMobile = false) => {
  if (mParticle.Identity?.getCurrentUser()) {
    const gaID = getGaUserId();
    if (gaID) {
      mParticle.Identity?.getCurrentUser()?.setUserAttribute(
        'googleAnalyticsId',
        gaID,
      );
    }
  }
  if (data?.customerid) {
    loginOnMparticle(data, isMobile);
  } else {
    mParticleLogout(isMobile);
  }
};

export const calculateSessionDuration = (
  sessionStartTime: string | undefined,
): string => {
  if (!sessionStartTime) {
    return '0';
  }

  const sessionEndTime = new Date().getTime();
  const sessionDuration = sessionEndTime - Number(sessionStartTime);
  const sessionDurationInSeconds = sessionDuration / 1000;

  return sessionDurationInSeconds.toString().split('.')[0];
};

export const getUrlParams = (
  attributes: LoginAttributes | LogoutAttributes | customAttributesType,
) => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmMedium = urlParams.get('utm_medium');
  const utmCampaign = urlParams.get('utm_campaign');
  const utmSource = urlParams.get('utm_source');

  return {
    ...attributes,
    utmMedium: utmMedium ?? undefined,
    utmCampaign: utmCampaign ?? undefined,
    utm: utmSource ?? undefined,
  };
};

export const getLoggedUserIdentities = (data?: DataType) => {
  const hasCustomerData = data?.email && data?.customerid;
  return hasCustomerData
    ? {
        email: data.email,
        customerid: `BU-FISIA_ID-${data.customerid}`,
      }
    : {};
};
